import { useEffect, useState } from "react";
import { BotData, Purchase, User } from "../../../types";
import { useLocation, useNavigate } from "react-router-dom";
import { MenuItem } from "./MenuItem";
import { ArrowRight, BarChart2, Code, CreditCard, LogOut, Menu, Monitor, Plus, Settings, UserPlus, Users, X } from "lucide-react";
import { SubMenu } from "./SubMenu";
import { classNames } from "../../../utils/classNames";
import { SidebarFooter } from "./SidebarFooter";

export const ResponsiveSidebar: React.FC<{
    user: User;
    purchases: Purchase;
    developer: boolean;
    onLogout: () => void;
    botData?: BotData;
}> = ({
    user,
    purchases,
    developer,
    onLogout,
    botData,
}) => {
        const [isCollapsed, setIsCollapsed] = useState(false);
        const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
        const [isMobile, setIsMobile] = useState(false);
        const [expandedMenus, setExpandedMenus] = useState<Record<string, boolean>>({
            tickets: false,
            monitors: false
        });
        const navigate = useNavigate();
        const location = useLocation();

        useEffect(() => {
            const handleResize = () => {
                setIsMobile(window.innerWidth < 768);
                if (window.innerWidth >= 768) {
                    setIsMobileMenuOpen(false);
                }
            };

            handleResize();
            window.addEventListener('resize', handleResize);
            return () => window.removeEventListener('resize', handleResize);
        }, []);

        const toggleMenu = (menu: string) => {
            setExpandedMenus(prev => ({
                ...prev,
                [menu]: !prev[menu]
            }));
        };

        const handleNavigation = (path: string) => {
            navigate(path);
            setIsMobileMenuOpen(false);
        };

        const handleInviteBot = () => {
            if (botData?.app_id && botData?.app_id !== "N/A") {
                window.open(
                    `https://discord.com/api/oauth2/authorize?client_id=${botData.app_id}&permissions=8&scope=bot`,
                    '_blank'
                );
            }
            else {
                alert("The bot is not setup yet, you will receive a discord dm once its finished.")
            }
        };

        const renderMainMenu = (mobile?: boolean) => (
            <div className="space-y-1">
                <MenuItem
                    icon={Settings}
                    label="Settings"
                    isActive={location.pathname === '/settings'}
                    isCollapsed={isCollapsed}
                    onClick={() => handleNavigation('/settings')}
                    isMobile={mobile}
                />
                <MenuItem
                    icon={Code}
                    label="Commands"
                    isActive={location.pathname === '/commands'}
                    isCollapsed={isCollapsed}
                    onClick={() => handleNavigation('/commands')}
                    isMobile={mobile}
                />
                {/* <MenuItem
                    icon={Code}
                    label="Updates"
                    isActive={location.pathname === '/updates'}
                    isCollapsed={isCollapsed}
                    onClick={() => handleNavigation('/updates')}
                    isMobile={mobile}
                /> */}
                <MenuItem
                    icon={CreditCard}
                    label="Payment"
                    isActive={location.pathname === '/payment'}
                    isCollapsed={isCollapsed}
                    onClick={() => handleNavigation('/payment')}
                    isMobile={mobile}
                />



                {'monitor' in purchases && (
                    <div className="space-y-1">
                        <MenuItem
                            icon={Monitor}
                            label="Monitors"
                            hasSubmenu
                            isOpen={expandedMenus.monitors}
                            isCollapsed={isCollapsed}
                            onClick={() => toggleMenu('monitors')}
                            isMobile={mobile}
                        />
                        {expandedMenus.monitors && !isCollapsed && (
                            <SubMenu
                                items={[
                                    { icon: BarChart2, label: "Overview", path: "/monitors" },
                                    { icon: ArrowRight, label: "Redirects", path: "/monitors/redirects" }
                                ]}
                                isCollapsed={isCollapsed}
                                currentPath={location.pathname}
                                onNavigate={handleNavigation}
                                isMobile={mobile}
                            />
                        )}
                    </div>
                )}

                <MenuItem
                    icon={UserPlus}
                    label="Dashboard Access"
                    isActive={location.pathname === '/dashboard-access'}
                    isCollapsed={isCollapsed}
                    onClick={() => handleNavigation('/dashboard-access')}
                    isMobile={mobile}
                />

                {developer && (
                    <MenuItem
                        icon={Users}
                        label="Customers"
                        isActive={location.pathname === '/customers'}
                        isCollapsed={isCollapsed}
                        onClick={() => handleNavigation('/customers')}
                        isMobile={mobile}
                    />
                )}
            </div>
        );

        // Mobile View

        if (isMobile) {
            return (
                <>
                    {/* Mobile Header */}
                    <div className="fixed top-0 left-0 right-0 h-16 bg-white dark:bg-gray-900 border-b border-gray-200 dark:border-gray-800 z-50 px-4 flex items-center justify-between">
                        <img src="/logo.png" alt="Logo" className="h-8 w-auto" />
                        <button
                            onClick={() => setIsMobileMenuOpen(!isMobileMenuOpen)}
                            className="p-2 rounded-lg text-gray-500 dark:text-gray-400"
                        >
                            {isMobileMenuOpen ? <X className="h-6 w-6" /> : <Menu className="h-6 w-6" />}
                        </button>
                    </div>

                    {/* Mobile Menu Overlay */}
                    {isMobileMenuOpen && (
                        <div
                            className="fixed inset-0 bg-gray-900 bg-opacity-50 z-40"
                            onClick={() => setIsMobileMenuOpen(false)}
                        />
                    )}

                    {/* Mobile Menu Content */}
                    <div className={classNames(
                        "fixed right-0 top-16 bottom-0 bg-white dark:bg-gray-900 z-40",
                        "w-64 border-l border-gray-200 dark:border-gray-800 overflow-y-auto",
                        "transform transition-transform duration-300 ease-in-out",
                        isMobileMenuOpen ? "translate-x-0" : "translate-x-full"
                    )}>
                        <div className="flex flex-col h-full">
                            <div className="flex-1 py-4">
                                {renderMainMenu(true)}
                            </div>

                            {/* Invite Bot Button */}
                            <div className="px-4 py-2">
                                <button
                                    onClick={handleInviteBot}
                                    className="w-full flex items-center justify-center gap-2 px-4 py-2 bg-gray-100 dark:bg-gray-800 rounded-lg text-sm font-medium text-gray-700 dark:text-gray-200 hover:bg-gray-200 dark:hover:bg-gray-700"
                                >
                                    <Plus className="h-5 w-5" />
                                    <span>Invite Bot</span>
                                </button>
                            </div>

                            {/* User Profile */}
                            <div className="border-t border-gray-200 dark:border-gray-800 p-4">
                                <div className="flex items-center justify-between">
                                    <div className="flex items-center">
                                        <img
                                            src={user.image}
                                            alt={user.name}
                                            className="h-8 w-8 rounded-full"
                                        />
                                        <span className="ml-3 text-sm font-medium text-gray-900 dark:text-white">
                                            {user.name}
                                        </span>
                                    </div>
                                    <button
                                        onClick={onLogout}
                                        className="p-2 rounded-lg text-gray-500 dark:text-gray-400 hover:bg-gray-100 dark:hover:bg-gray-800"
                                    >
                                        <LogOut className="h-5 w-5" />
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>

                </>
            );
        }
        return (
            <aside className={classNames(
                "md:flex flex-col h-screen border-r border-gray-200 dark:border-gray-800",
                "bg-white dark:bg-[#0D1422] transition-all duration-300",
                isCollapsed ? "w-20" : "w-64"
            )}>

                {/* Header */}
                <div className={classNames(
                    "flex items-center border-b border-gray-200 dark:border-gray-800",
                    "h-16 px-4 transition-all duration-300"
                )}>
                    {!isCollapsed && (
                        <img src="/logo.png" alt="Logo" className="h-8 w-auto" />
                    )}
                    <button
                        onClick={() => setIsCollapsed(!isCollapsed)}
                        className={classNames(
                            "p-2 rounded-lg hover:bg-gray-100 dark:hover:bg-gray-800",
                            "text-gray-500 dark:text-gray-400 transition-colors",
                            isCollapsed ? "mx-auto" : "ml-auto"
                        )}
                    >
                        {isCollapsed ? (
                            <Menu className="h-5 w-5" />
                        ) : (
                            <X className="h-5 w-5" />
                        )}
                    </button>
                </div>

                {/* Navigation */}
                <nav className="flex-1 overflow-y-auto py-4 px-3">
                    {renderMainMenu()}
                </nav>

                {/* Footer */}
                <SidebarFooter isCollapsed={isCollapsed} user={user} onLogout={onLogout} botData={botData} />
            </aside>
        );
    };

export default ResponsiveSidebar;